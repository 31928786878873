import { For, useContext } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Button, Link } from '@troon/ui';
import { IconArrowRightMd } from '@troon/icons';
import { GenericLayout } from '../../layouts/generic';
import { getConfigValue } from '../../modules/config';
import { CardsCtx } from '../../providers/card';

export default function TroonCard() {
	const cards = useContext(CardsCtx);

	return (
		<GenericLayout
			heroContent={() => <h1>Troon Card</h1>}
			hero={`https://${getConfigValue('MAP_HOST')}/assets/images/troon-north.jpg`}
		>
			<Title>Troon Card | Exclusive savings at over 150 participating golf courses | Troon</Title>
			<ul>
				<For each={cards()}>
					{(card, index) => (
						<li>
							<h2>{card.subscriptionName}</h2>
							<p>{card.amount.displayValue}/yr</p>
							<Button
								as={Link}
								href={`/card/checkout/?id=${card.id}`}
								appearance={index() === 0 ? 'primary' : 'secondary'}
							>
								Buy<span class="sr-only"> the {card.subscriptionName}</span> now <IconArrowRightMd />
							</Button>
						</li>
					)}
				</For>
			</ul>
		</GenericLayout>
	);
}
